import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex gap-y-6 flex-col pb-6" }
const _hoisted_2 = { class: "flex gap-y-6 flex-col sm:flex-row flex-wrap pr-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_ChipsV2 = _resolveComponent("ChipsV2")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!

  return (_openBlock(), _createBlock(_component_DetailLayout, {
    scrollSidebar: "",
    customClass: "px-0",
    onBack: _ctx.goBack,
    title: "Detail Top Up Fee",
    loading: _ctx.apiDetailData.loading,
    error: !!_ctx.apiDetailData.errorType,
    errorType: _ctx.apiDetailData.errorType,
    onTryAgain: _ctx.fetchDetail
  }, {
    data: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Tipe Akun",
            value: _ctx.detailData.data.topupFeeAccountType
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Nama POS",
            value: _ctx.posName
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-full",
            label: "Biaya Admin",
            value: _ctx.detailData.data.formatTopupFee
          }, null, 8, ["value"])
        ])
      ])
    ]),
    "sub-data": _withCtx(() => [
      _createVNode(_component_DataWrapper, { label: "Status" }, {
        default: _withCtx(() => [
          _createVNode(_component_ChipsV2, {
            class: "pt-2",
            label: _ctx.detailData.data.topupFeeStatus
          }, null, 8, ["label"])
        ]),
        _: 1
      }),
      _createVNode(_component_DataWrapper, {
        label: "Terakhir Diubah",
        value: _ctx.detailData.data.formatCreatedAt,
        caption: _ctx.detailData.data.topupFeeCreatedBy
      }, null, 8, ["value", "caption"]),
      _createVNode(_component_DataWrapper, {
        class: "capitalize",
        label: "Tanggal Dibuat",
        value: _ctx.detailData.data.formatUpdatedAt,
        caption: _ctx.detailData.data.topupFeeUpdatedBy
      }, null, 8, ["value", "caption"]),
      _withDirectives(_createVNode(_component_LpButton, {
        title: "Ubah",
        customClass: "w-1/2",
        onClick: _ctx.goToEdit,
        outline: "",
        textColor: "red-lp-100"
      }, null, 8, ["onClick"]), [
        [_vShow, _ctx.isEditAble]
      ])
    ]),
    _: 1
  }, 8, ["onBack", "loading", "error", "errorType", "onTryAgain"]))
}