
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { TopupFeeConfigurationController } from "@/app/ui/controllers/TopupFeeConfigurationController";
import { ApiStateData } from "@/domain/entities/Api";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { FlagsPermissionTopUpAndCashback } from "@/feature-flags/flags-permission-topup-and-cashback";
import { Vue } from "vue-class-component";

export default class DetailTopupFeeConfiguration extends Vue {
  get isEditAble() {
    return FlagsPermissionTopUpAndCashback.permission_finance_top_up_fee_enable.isEnabled();
  }

  goBack() {
    this.$router.push("/finance/top-up-and-cashback");
  }

  mounted() {
    this.fetchDetail();
  }

  get id() {
    return this.$route.params.id;
  }

  get posName() {
    return this.detailData.data.topupFeeIsAllPos
      ? "Semua POS"
      : this.detailData.data.topupFeePartners
          ?.map((key: any) => " " + key.name)
          .toString();
  }

  apiDetailData = new ApiStateData();
  detailData = new ResponsePayloadV2();

  async fetchDetail() {
    try {
      MainAppController.showLoading();
      this.apiDetailData.loading = true;
      this.detailData = await TopupFeeConfigurationController.getDetailTopupFeeConfiguration(
        this.id
      );
      this.apiDetailData.errorType = "";
    } catch (error) {
      this.apiDetailData.errorType =
        parsingErrorResponse(error).type === "internet-error"
          ? "internet"
          : parsingErrorResponse(error).type;
    } finally {
      MainAppController.closeLoading();
      this.apiDetailData.loading = false;
    }
  }

  goToEdit() {
    this.$router.push(`/finance/top-up-and-cashback/top-up-fee/${this.id}/edit`);
  }
}
